import logo from './logo.svg';
import './App.css';
import './Media.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import Header from './modules/Header';
import TopBar from './modules/TopBar';
import OurProjects from './modules/OurProjects';
import DevTeams from './modules/DevTeams';
import OmissionDesktopApp from './modules/OmissionDesktopApp';
import OurBrands from './modules/OurBrands';
import Footer from './modules/Footer';
function App() {
  return (
        <div className="App">
            <Header />
            <TopBar />
            <OurProjects />
            <DevTeams />
            <OmissionDesktopApp />
            <OurBrands />
            <Footer />
        </div>
  );
}

export default App;
