import React from 'react';
import devtraining from '../img/dev-training.png';
import devwmo from '../img/dev-wmo.png';
import devomission from '../img/dev-omission.png';
import logo from '../img/logo.png';
function Footer() {
    return (
        <div className="gray-bg footer-gray-bg">
            <div className="wrapper footer-wrapper">
                <div className="left-footer-content">
                    <img src={logo} alt="Careplanner" className="left-footer-content-logo" />
                    <div className="left-footer-content-adress">
                        <span>Lageweg 12 B</span>
                        <span>3815VG</span>
                        <span>Amersfoort</span>
                    </div>
                    <div className="left-footer-content-contact">
                        <span>(088) 234 00 00 </span>
                        <span>info@careplanner.nl</span>
                    </div>
                </div>
                <div className="right-footer-content">
                    <div className="right-footer-content-image-container"><img src={devomission} alt="" className="dev-team-img" /></div>
                    <div className="right-footer-content-image-container"><img src={devwmo} alt="" className="dev-team-img" /></div>
                    <div className="right-footer-content-image-container"><img src={devtraining} alt="" className="dev-team-img" /></div>
                </div>
                
            </div>
            <div className="footer-sidenode">Careplanner 2023 ©</div>
        </div>
    );
}

export default Footer;