import React from 'react';
import sbhoursregistration from '../img/sb-hours-registration.png';
import sbphonetoday from '../img/sb-phone-today.png';
import sbomission from '../img/sb-omission.png';
import sbcarepad from '../img/sb-carepad.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDisplay } from '@fortawesome/free-solid-svg-icons'
function OurProjects() {
    return (
        <div className="wrapper block-wrapper">
            <h2 className="heading-black">Onze projecten</h2>
            <div className="project-wrapper">
                <img src={sbphonetoday} alt="" className="project-wrapper-img" />
                <div className="project-wrapper-text">
                    <h3 className="heading-green">Phone today</h3>
                    <p>
                        Verken de mogelijkheden van ons VOIP-platform.
                        Met onze desktop app blijft u altijd en overal verbonden.
                        Eenvoud en overzicht staan centraal in ons beheersysteem.
                        Voor diegenen die flexibiliteit zoeken in hun werk: wij hebben het antwoord.
                        Flexwerken is bij ons niet enkel een term, maar een belofte.
                        Wij begrijpen de uitdagingen van de moderne werkomgeving.
                        Ons platform is ontworpen met uw behoeften in gedachten.
                        Betrouwbaarheid en gebruiksgemak staan voorop.
                        Neem de tijd om de voordelen zelf te ontdekken.
                        Maak de overstap en voel het verschil in communicatie
                    </p>
                    <FontAwesomeIcon icon={faDisplay} className="project-wrapper-fa-display" /><a className="project-wrapper-link" href="#">Binnenkort beschikbaar</a>
                </div>
            </div>
            <div className="project-wrapper">
                <img src={sbcarepad} alt="" className="project-wrapper-img" />
                <div className="project-wrapper-text">
                    <h3 className="heading-green">CarePad</h3>
                    <p>
                        Met zijn grote, duidelijke knoppen en een intuïtieve lay-out maakt onze tablet het gemakkelijker dan ooit voor senioren om contact te houden met geliefden,
                        nieuwe hobby's te ontdekken of simpelweg de wereld van het internet te verkennen. 
                        En terwijl de digitale wereld soms onveilig kan aanvoelen, hebben we gezorgd voor een veilige en beschermde omgeving voor het downloaden van apps, zodat de gebruiker zonder zorgen de wonderen van technologie kan ervaren.
                    </p>
                    <FontAwesomeIcon icon={faDisplay} className="project-wrapper-fa-display" /><a href="https://www.carepad.nl" target="_blank" className="project-wrapper-btn">Carepad.nl</a>
                </div>
            </div>
            <div className="project-wrapper">
                <img src={sbhoursregistration} alt="" className="project-wrapper-img" />
                <div className="project-wrapper-text">
                    <h3 className="heading-green">Urenregistratie module</h3>
                    <p>
                        Voor de medewerkers in de huishouding die elke dag hard werken en elke minuut tellen, introduceren we onze innovatieve app voor urenaccordering. Geen papierwerk, geen gedoe, enkel eenvoud.
                        Bij aankomst op locatie open je simpelweg de app, voer je de gewerkte uren in en met een snelle handtekening zijn ze geaccordeerd. En wat nog beter is? Zodra die uren zijn goedgekeurd, wordt de betaling automatisch verwerkt.
                    </p>
                    <b className="project-wrapper-sidenode">Deze module wordt in verschillende applicaties gebruikt</b>
                </div>
            </div>
            <div className="project-wrapper">
                <img src={sbomission} alt="" className="project-wrapper-img" />
                <div className="project-wrapper-text">
                    <h3 className="heading-green">Verzuim applicatie voor desktop</h3>
                    <p>
                        Met onze applicatie kunt u proactief inspelen op verzuimpatronen, waardoor u het welzijn van uw werknemers kunt waarborgen en tegelijkertijd de bedrijfsefficiëntie kunt verhogen. Het is tijd om de manier waarop u verzuim benadert te transformeren en een omgeving te creëren waarin iedereen zich gezien en begrepen voelt.
                    </p>
                    <b className="project-wrapper-sidenode">Deze desktop applicaties is in ontwikeling</b>
                </div>
            </div>
        </div>
    );
}

export default OurProjects;