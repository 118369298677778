import React from 'react';
import phonetodaylogo from '../img/phone-today-phone.png';
import phonetodayimg from '../img/phone-today-img.png';
function TopBar() {
    return (
        <div className="gray-bg">
            <div className="wrapper">
                <div className="intro-content-box">
                    <img src={phonetodaylogo} alt="Careplanner" className="ptd-logo" />
                    <div className="intro">
                        <h1><span className="mohr-purple">De slimste</span> oplossing voor jouw <span className="mohr-orange">zakelijke telefonie</span></h1>
                        <p>Al jouw telefonie en afspraken optimaal managen met onze slimme desktop app. Zonder zorgen en optimaal bereikbaar met onze online telefooncentrale. Je kan zorgeloos bellen met een glimlach.</p>
                        <img src={phonetodayimg} alt="Careplanner" className="intro-content-box-img" />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopBar;