import React from 'react';
import devtraining from '../img/dev-training.png';
import devwmo from '../img/dev-wmo.png';
import devomission from '../img/dev-omission.png';

function DevTeams() {
    return (
        <div className="gray-bg">
            <div className="wrapper column-wrapper">
                <h1 className="dev-teams-title">CarePlanner<span>XTRA</span> Development teams</h1>
                <div className="dev-teams-container">
                    <div className="dev-team">
                        <div className="dev-team-image-container"><img src={devomission} alt="" className="dev-team-img" /></div>
                        <p className="dev-team-text">
                            Bij team verzuim zijn we hard aan het werk de verzuim applicatie voor de desktop te realiseren. 
                            <br/><br/>
                            Met deze applicatie bent u volledig in controle over het verzuim binnen uw bedrijf. Er is zelf een portaal binnen deze app voor alle extrerne hulp zoals een arbo-arts zonder dat u bij deze gegevens kunt als eigenaar van uw bedrijf. Helemaal voorzien van alle regelgeving die nodig is.
                        </p>
                    </div>
                    <div className="dev-team">
                        <div className="dev-team-image-container"><img src={devwmo} alt="" className="dev-team-img" /></div>
                        <p className="dev-team-text">
                            App wmo zorgt voor de ontwikkeling van de apps voor de huishoudelijke hulp. Denk hierbij an planning, verlof en nieuwsmeldingen naar uw personeel.
                            <br/><br/>
                            Met deze applicatie bent u volledig in controle over het verzuim binnen uw bedrijf. Er is zelf een portaal binnen deze app voor alle extrerne hulp zoals een arbo-arts zonder dat u bij deze gegevens kunt als eigenaar van uw bedrijf. Helemaal voorzien van alle regelgeving die nodig is.
                        </p>
                    </div>
                    <div className="dev-team">
                        <div className="dev-team-image-container"><img src={devtraining} alt="" className="dev-team-img" /></div>
                        <p className="dev-team-text">
                            Bij Careplanner zijn we bezig een speciale trainings en studie applicatie te maken die u kunt samenstellen voor uw eigen bedrijf. Vind de juiste trainingen, pas deze toe en laat uw personeel de toets uitvoeren. Uw bedrijf zit nooit meer zonder ongecertificeerd personeel.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DevTeams;
