import React from 'react';
import brcarepad from '../img/br-cp.png';
import brcareplanner from '../img/br-carpl.png';
import brphonetoday from '../img/br-phonetd.png';

function OurBrands() {
    return (
        <div className="wrapper column-wrapper">
            <h1 className="our-brand-title">Onze merken</h1>
            <div className="our-brands-container">
                <div className="our-brand">
                    <div className="our-brand-image-container"><img src={brphonetoday} alt="" className="our-brand-img" /></div>
                    <h3 className="brand-name-title">Phone Today</h3>
                    <p className="our-brand-text">
                        Al jouw telefonie en afspraken optimaal managen met onze slimme desktop app. Zonder zorgen en optimaal bereikbaar met onze online telefooncentrale. Je kan zorgeloos bellen met een glimlach.
                    </p>
                </div>
                <div className="our-brand">
                    <div className="our-brand-image-container"><img src={brcareplanner} alt="" className="our-brand-img" /></div>
                    <h3 className="brand-name-title">CP Verzuim app</h3>
                    <p className="dev-team-text">
                        Houd simpel en gemakkelijke het  overzicht op het verzuim van uw medewerkers.
                    </p>
                </div>
                <div className="our-brand">
                    
                    <div className="our-brand-image-container"><img src={brcarepad} alt="" className="our-brand-img" /></div>
                    <h3 className="brand-name-title">CarePad</h3>
                    <p className="our-brand-text">
                        De tablet voor ouderen. Altijd in contact met uw naasten, Handig 
                        te bedienen en ergonimisch 
                        verantwoord.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default OurBrands;
