import React from 'react';
import logo from '../img/logo.png';
import '../App.css';  // Assuming you have a CSS file with this name. Adjust accordingly.

function OmissionDesktopApp() {
    return (
        <div className="outer-wrapper">
            <div className="wrapper">
                <div className="omission-title">
                    <img src={logo} alt="Careplanner" className="omission-title-element omission-logo" />
                    <h2 className="heading-black omission-title-element">Verzuim desktop app</h2>
                </div>
            </div>
            <div className="omission-wrapper">
                <div className="wrapper right-wrapper">
                    <p>
                        Verzuim op de werkvloer kan ingewikkeld zijn. Het vraagt om zowel begrip als overzicht, en in een bedrijf waar iedereen druk is, kan dit een hele uitdaging zijn. Met dit in gedachten hebben wij een applicatie ontwikkeld die bedoeld is om eenvoud in deze complexiteit te brengen.
                        <br /><br />
                        Onze tool biedt een rustig en duidelijk overzicht van het verzuim binnen uw organisatie. In plaats van talloze bestanden of systemen door te moeten nemen, krijgt u met onze applicatie snel inzicht in de nodige gegevens. Hiermee kunt u weloverwogen beslissingen nemen, gebaseerd op duidelijke informatie.
                        <br /><br />
                        Het welzijn van uw medewerkers is essentieel. En terwijl we technologie gebruiken om het verzuim te monitoren, herinneren we ons altijd dat er achter elke statistiek een individu staat. Onze applicatie is ontworpen met respect en begrip voor die individuen.
                    </p>
                </div>
                <div className="right-side-wrapper"></div>
            </div>
        </div>
    );
}

export default OmissionDesktopApp;